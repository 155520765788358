<template>
  <!-- 加工厂管理 -->
  <div class="page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div class="">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <Dialog
      ref="dialogRef"
      dialog-width="30%"
      :class="{'hide-footer': dialogConfig.dialogTitle === '审核结果' || dialogConfig.dialogTitle === '关闭原因'}"
      :need-dialog-title="dialogConfig.dialogTitle"
      :form-item="dialogConfig.dialogItem"
      :edit-form-data="dialogConfig.dialogForm"
      @getFormData="getDialogFormData"
      @getFormItem="getDialogFormItem"
    >
      <template #reviewResult>
        <!-- 审核结果 -->
        <p>{{ getDictName([{dictName: '通过', dictId: '01'}, {dictName: '驳回', dictId: '00'}], dialogConfig.dialogForm.reviewResult) }}</p>
      </template>
      <template #reviewRealname>
        <!-- 审核人 -->
        <p>{{ dialogConfig.dialogForm.reviewRealname + '  (' + dialogConfig.dialogForm.reviewUser + ')' }}</p>
      </template>
      <template #reviewTime>
        <!-- 审核时间 -->
        <p>{{ dialogConfig.dialogForm.reviewTime }}</p>
      </template>
      <template #reviewComment>
        <!-- 审核意见 -->
        <p>{{ dialogConfig.dialogForm.reviewComment }}</p>
      </template>

      <!-- 关闭原因 -->
      <template #closeRealname>
        <!-- 操作人 -->
        <p>{{ dialogConfig.dialogForm.closeRealname + '  (' + dialogConfig.dialogForm.closeUser + ')' }}</p>
      </template>
      <template #closeTime>
        <!-- 操作时间 -->
        <p>{{ dialogConfig.dialogForm.closeTime }}</p>
      </template>
      <template #closeComment>
        <!-- 关闭意见 -->
        <p>{{ dialogConfig.dialogForm.closeComment }}</p>
      </template>
      <template #closeAttachmentName>
        <!-- 文件名称 -->
        <p>{{ dialogConfig.dialogForm.closeAttachmentName }} <span v-if="dialogConfig.dialogForm.closeAttachment" class="view-old-material" @click="viewOldMaterial('view')">查看</span> <span v-if="dialogConfig.dialogForm.closeAttachment" class="view-old-material" @click="viewOldMaterial">下载</span></p>
      </template>
    </Dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Pagination from '@/components/Pagination2.vue'
import Dialog from '@/components/Dialog.vue'
import { getDictName, downloadFile2 } from '@/utils/util.js'
import { managerListPageFactory, managerReviewFactoryBusiness, managerOpenFactory, managerCloseFactory } from '@/api/processingServices'
import { getSelectAreaTree } from '@/api/login.js'
export default {
  components: { FormSearch, Table, Pagination, Dialog },
  data() {
    return {
      getDictName,
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      searchFormItemArr: [
        { type: 'input', label: '公司名称', value: 'cmpName', width: '80px' },
        { type: 'cascader', label: '厂房地区', value: 'factoryAddress', options: [], props: { value: 'code', label: 'name', checkStrictly: true }, width: '80px' },
        { type: 'select', label: '状态', value: 'state', width: '80px', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('FACTORY_STATE') }
      ],
      itemData: [
        { label: '公司名称', prop: 'cmpName', width: 200 },
        { label: '厂房地区', prop: 'placeArr', width: 260 },
        { label: '联系人', prop: 'contactPerson', width: 200 },
        { label: '联系电话', prop: 'contactPhone', width: 120 },
        { label: '申请时间', prop: 'applyTime', width: 280 },
        {
          label: '状态',
          prop: 'state',
          width: 180,
          child: this.$store.getters.getDictionaryItem('FACTORY_STATE')
        }
      ],
      operationButton: [
        { bType: 'primary', label: '审核', handleEvent: this.toExamine, val: 'state', num: '01' },
        { bType: 'primary', label: '审核结果', handleEvent: this.toExamineResult, childOne: [{ val: 'state', num: '02' }, { val: 'state', num: '03' }] },
        { bType: 'primary', label: '开启加工厂', handleEvent: this.openProcess, val: 'state', num: '04' },
        { bType: 'primary', label: '关闭加工厂', handleEvent: this.closeProcess, val: 'state', num: '03' },
        { bType: 'primary', label: '关闭原因', handleEvent: this.closeProcessReason, val: 'state', num: '04' },
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
      ],
      listData: [],
      total: 0,
      loading: false,
      dialogConfig: {
        dialogTitle: '',
        dialogItem: [],
        dialogForm: {},
        API: ''
      }
    }
  },
  mounted() {
    this.getdata()
    // 获取地区
    const filterLevel = (list, level = 1) => list.map(v => ({ ...v, children: level <= 1 ? null : filterLevel(v.children, level - 1) }))
    getSelectAreaTree('', (res) => {
      this.searchFormItemArr[1].options = filterLevel(res.data, 2)
    })
  },
  methods: {
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      const params = {
        factoryProvince: this.formInline.factoryAddress ? this.formInline.factoryAddress[0] : '',
        factoryCity: this.formInline.factoryAddress ? this.formInline.factoryAddress[1] : '',
        ...this.formInline
      }
      managerListPageFactory(params, res => {
        if (res.data.records) {
          res.data.records.forEach((item) => {
            item.placeArr = item.factoryProvinceName + '/' + item.factoryCityName
          })
          this.listData = [...res.data.records]
          this.total = res.data.total
          return
        }
        this.listData = []
        this.total = 0
      })
    },
    // 搜索栏 级联获取数据
    areaCategoryChange(val) {
      if (!val) return
      this.formInline.factoryProvince = val[0]
      this.formInline.factoryCity = val[1]
    },
    // 审核
    toExamine({ id }) {
      this.$refs.dialogRef.editFormVisible = true
      this.dialogConfig.dialogTitle = '审核加工厂'
      this.dialogConfig.API = managerReviewFactoryBusiness
      this.dialogConfig.dialogForm.id = id
      this.dialogConfig.dialogItem = [
        {
          label: '审核结果',
          type: 'radio',
          value: 'reviewResult',
          child: [
            { label: '通过', value: '01' },
            { label: '驳回', value: '00' }
          ],
          rules: [
            {
              required: true,
              message: '请选择',
              trigger: 'change'
            }
          ]
        },
        {
          label: '审核意见',
          type: 'input',
          value: 'reviewComment',
          rules: [
            {
              required: false,
              message: '请输入审核意见',
              trigger: 'blur'
            },
            { min: 1, max: 30, message: '请输入1-30个字符', trigger: 'blur' }
          ]
        }
      ]
    },
    // 审核结果
    toExamineResult(row) {
      this.$refs.dialogRef.editFormVisible = true
      this.dialogConfig.dialogTitle = '审核结果'
      this.dialogConfig.dialogForm = { ...row }
      this.dialogConfig.dialogItem = [
        {
          label: '审核结果',
          slotName: 'reviewResult'
        },
        {
          label: '审核人',
          slotName: 'reviewRealname'
        },
        {
          label: '审核时间',
          slotName: 'reviewTime'
        },
        {
          label: '审核意见',
          slotName: 'reviewComment'
        }
      ]
    },
    // 关闭加工厂
    closeProcess({ id }) {
      this.$refs.dialogRef.editFormVisible = true
      this.dialogConfig.dialogTitle = '关闭加工厂'
      this.dialogConfig.API = managerCloseFactory
      this.dialogConfig.dialogForm.id = id
      this.dialogConfig.dialogItem = [
        {
          label: '关闭意见',
          type: 'input',
          value: 'closeComment',
          rules: [
            {
              required: true,
              message: '请输入关闭意见',
              trigger: 'blur'
            },
            { min: 1, max: 30, message: '请输入1-30个字符', trigger: 'blur' }
          ]
        },
        {
          label: '附件',
          value: 'closeAttachment',
          fileName: 'closeAttachmentName',
          type: 'fileUpload',
          fileFormat: 'pdf/jpg/png/jpeg',
          rules: [
            {
              required: false,
              message: '请上传附件',
              trigger: ['change', 'blur']
            }
          ]
        }
      ]
    },
    // 开启加工厂
    async openProcess({ id }) {
      const confirm = await this.$confirm('开启后，该商家将继续在小链科技平台提供加工服务', '开启加工厂', { type: 'warning' }).catch(err => err)
      console.log(confirm)
      if (confirm !== 'confirm') return
      managerOpenFactory(id, res => {
        this.$message.success(res.msg)
        this.getdata()
      })
    },
    // 关闭加工厂原因
    closeProcessReason(row) {
      this.$refs.dialogRef.editFormVisible = true
      this.dialogConfig.dialogTitle = '关闭原因'
      this.dialogConfig.dialogForm = { ...row }
      this.dialogConfig.dialogItem = [
        {
          label: '操作人',
          slotName: 'closeRealname'
        },
        {
          label: '操作时间',
          slotName: 'closeTime'
        },
        {
          label: '关闭意见',
          slotName: 'closeComment'
        },
        {
          label: '附件',
          slotName: 'closeAttachmentName'
        }
      ]
    },
    // 获取表单单选状态
    getDialogFormItem(val) {
      const flag = val === '00'
      this.dialogConfig.dialogItem.forEach((item) => {
        if (item.value === 'reviewComment') item.rules[0].required = flag
      })
    },
    // 附件查看
    viewOldMaterial(e) {
      if (e === 'view') return window.open(this.$fileUrl + this.dialogConfig.dialogForm.closeAttachment)
      downloadFile2(this.$fileUrl + this.dialogConfig.dialogForm.closeAttachment, this.dialogConfig.dialogForm.closeAttachmentName)
    },
    // 获取弹窗数据
    getDialogFormData(data) {
      const params = {
        ...data,
        id: this.dialogConfig.dialogForm.id
      }
      this.dialogConfig.API(params, res => {
        this.$message.success(res.msg)
        this.$refs.dialogRef.editFormVisible = false
        this.dialogConfig.dialogItem = []
        this.getdata()
      })
    },
    // 查看详情
    viewDetails({ id }) {
      this.$router.push({
        path: '/storageProcess/processManageDetails',
        query: { id }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.view-old-material {
  color: $mainBlue;
  margin-left: 20px;
  cursor: pointer;
}
/deep/ .hide-footer {
  .el-dialog__footer {
    display: none;
  }
}
</style>
